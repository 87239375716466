module.exports = {
  siteTitle: 'Approvol', // Site title.
  siteTitleAlt: 'Approvol Budgeting & Procurement Management System', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://approvol.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Approvol - a budgeting & procurement management system for your organisation.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  googleAnalyticsTrackingId: 'G-7XS9BHDEDR',
  userName: 'approvol',
  userTwitter: 'approvol',
  userLocation: 'United Kingdom',
  userDescription: 'approvol® Purchase Management System',
  copyright: '© Approvol Ltd', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#27A169', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
}
