import React from 'react'
import config from '../../../data/config'
import { Link } from 'gatsby'
import { FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa'

const captions = {
  '': {
    solutions: 'Solutions',
    useCases: 'Use Cases',
    sectors: 'Sectors',
    pricing: 'Pricing',
    about: 'About',
    blog: 'Blog',
    contact: 'Contact',
    signIn: 'Sign In',
    serviceDesk: 'Service Desk',
    serviceStatus: 'Service Status',
    terms: 'Terms',
    privacy: 'Privacy',
    legalInformation: 'Company number 11874094 (Registered in England & Wales)',
  },
  'zh-cn': {
    solutions: '系统介绍',
    useCases: '应用场景',
    sectors: '应用行业',
    pricing: '价格方案',
    about: '关于Approvol',
    blog: 'Blog',
    contact: '联系我们',
    signIn: '登录系统',
    serviceDesk: '客服系统',
    serviceStatus: '系统状态',
    terms: '使用条款',
    privacy: '隐私协议',
    legalInformation: '公司編號 11874094 (英格蘭和威爾士註冊公司)',
  },
}

const appUrlsByLang = {
  '': 'https://app.approvol.com',
  'zh-cn': 'https://app-zhcn.approvol.com',
}

const Footer = ({ lang }) => {
  const langUriPrefix = lang ? '/' + lang : ''
  return (
    <footer className='footer'>
      <div className='section'>
        <div className='container'>
          <div className='columns is-multiline is-mobile'>
            <div className='column is-full-mobile is-offset-1-desktop'>
              <a href='/'>
                <img src='/img/logo-only-mono.svg' width='40px' alt='Approvol Logo' />
              </a>
            </div>
            <div className='column is-half-mobile has-text-weight-medium'>
              <div className='has-text-grey-dark has-text-weight-medium is-hidden'>
                PRODUCT
              </div>
              <div>
                <Link to={`${langUriPrefix}/solutions`}>
                  {captions[lang].solutions}
                </Link>
              </div>
              <div>
                <Link to={`${langUriPrefix}/use-cases`}>
                  {captions[lang].useCases}
                </Link>
              </div>
              <div>
                <Link to={`${langUriPrefix}/sectors`}>
                  {captions[lang].sectors}
                </Link>
              </div>
              <div>
                <Link to={`${langUriPrefix}/pricing`}>
                  {captions[lang].pricing}
                </Link>
              </div>
              <div>
                <Link to={`${langUriPrefix}/about`}>
                  {captions[lang].about}
                </Link>
              </div>
              <div>
                <Link to={`${langUriPrefix}/contact`}>
                  {captions[lang].contact}
                </Link>
              </div>
            </div>
            <div className='column is-half-mobile has-text-weight-medium'>
              <div className='has-text-grey-dark has-text-weight-medium is-hidden'>
                RESOURCES
              </div>
              <div>
                <a href={appUrlsByLang[lang]}>
                  {captions[lang].signIn}
                </a>
              </div>
              <div>
                <Link to='https://approvol.freshdesk.com'>
                  {captions[lang].serviceDesk}
                </Link>
              </div>
              <div>
                <Link to='https://status.approvol.com'>
                  {captions[lang].serviceStatus}
                </Link>
              </div>
              {lang ? '' : (
                <div>
                  <Link to={`${langUriPrefix}/blog`}>
                    {captions[lang].blog}
                  </Link>
                </div>
              )}
            </div>
            <div className='column is-half-mobile'>
              <div className='has-text-grey-dark has-text-weight-medium is-hidden'>
                LEGAL
              </div>
              <div>
                <Link to={`${langUriPrefix}/terms`}>
                  {captions[lang].terms}
                </Link>
              </div>
              <div>
                <Link to={`${langUriPrefix}/privacy`}>
                  {captions[lang].privacy}
                </Link>
              </div>
            </div>
            <div className='column is-narrow is-half-mobile'>
              <div className='has-text-grey-dark has-text-weight-medium is-hidden'>
                SOCIAL MEDIA
              </div>
              <div>
                <a href='https://twitter.com/approvol' target='_blank'>
                  <FaTwitter size='1.5em' />
                </a>
              </div>
              <div>
                <a href='https://linkedin.com/company/approvol' target='_blank'>
                  <FaLinkedin size='1.5em' />
                </a>
              </div>
              <div>
                <a href='https://facebook.com/approvol' target='_blank'>
                  <FaFacebook size='1.5em' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='container has-text-centered'>
          <span className='has-text-grey-dark has-text-weight-medium mx-1'>
            {config.copyright}
          </span>
          &nbsp;
          <span className='has-text-grey has-text-weight-normal mx-1'>
            {captions[lang].legalInformation}
          </span>
        </div>
      </div>
    </footer>
  )
}

export default Footer
