import React, { useState } from 'react'
import Helmet from 'react-helmet'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import LangContext from '../components/LangContext'
import '../assets/sass/styles.scss'
import config from '../../data/config'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'

const additionalLangs = ['zh-cn']

const TemplateWrapper = ({
  uri,
  children,
}) => {
  const [isActive, setIsActive] = useState(false)
  const location = useLocation()
  const lang = additionalLangs.find((lang) => uri.toLowerCase().includes('/' + lang)) || ''
  const htmlLang = lang.includes('-') ? lang.split('-')[0] : 'en'
  const langClassName = 'lang-' + lang

  const toggleNavbar = () => {
    setIsActive(!isActive)
  }

  return (
    <div className={langClassName}>
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta name='description' content={config.siteDescription} />
        <html lang={htmlLang} />
      </Helmet>
      <NavBar isActive={isActive} toggleNavbar={() => toggleNavbar()} lang={lang} />
      <div>
        <LangContext.Provider value={lang}>
          {children}
        </LangContext.Provider>
      </div>
      <Footer lang={lang} />
      <CookieConsent
        location='none'
        buttonText='Accept'
        cookieName='approvol-gdpr-google-analytics'
        onAccept={() => initializeAndTrack(location)}
        style={{
          alignItems: 'center',
          left: '10px',
          bottom: '10px',
          width: 'calc(100% - 20px)',
        }}
        buttonClasses='button is-info is-size-4-desktop is-size-5-touch has-button-shadow m-4'
        disableButtonStyles
        contentClasses='is-size-5-desktop is-size-6-touch'>
        This website stores cookies on your computer.
        We use cookies to collect information about how you interact with this website and allow us to remember you.
        Learn more in our <Link to='/privacy' className='has-text-link-light'><u>cookie policy</u></Link>.
      </CookieConsent>
    </div >
  )
}

export default TemplateWrapper
