import React from 'react'
import { Link } from 'gatsby'
// import SearchBox from '../SearchBox'

const captions = {
  '': {
    solutions: 'SOLUTIONS',
    useCases: 'USE CASES',
    sectors: 'SECTORS',
    pricing: 'PRICING',
    about: 'ABOUT',
    blog: 'BLOG',
    contactUs: 'CONTACT US',
    signIn: 'SIGN IN',
    budgetControl: 'Budget Control',
    budgetControlHeading: 'EXACTLY WHAT YOU NEED',
    purchaseRequests: 'Purchase Requests',
    purchaseRequestsHeading: 'TAKE THE GUESSWORK OUT',
    orderDeliveries: 'Order Deliveries',
    orderDeliveriesHeading: 'KEEP ON TRACK',
    invoiceProcessing: 'Invoice Processing',
    invoiceProcessingHeading: 'STAY IN CONTROL',
    approvalRules: 'Approval Rules',
    approvalRulesHeading: 'MADE FOR YOUR ORGANISATION',
    assetTracking: 'Asset Tracking',
    assetTrackingHeading: 'TRACEABILITY ENSURED',
    analytics: 'Analytics',
    analyticsHeading: 'SEE THE WHOLE PICTURE',
    dataIntegration: 'Data & Integration',
    dataIntegrationHeading: 'TIE EVERYTHING TOGETHER',
  },
  'zh-cn': {
    solutions: '系统介绍',
    useCases: '应用场景',
    sectors: '应用行业',
    pricing: '价格方案',
    about: '关于Approvol',
    blog: 'BLOG',
    contactUs: '联系我们',
    signIn: '登录系统',
    budgetControl: 'Budget Control',
    budgetControlHeading: 'EXACTLY WHAT YOU NEED',
    purchaseRequests: 'Purchase Requests',
    purchaseRequestsHeading: 'TAKE THE GUESSWORK OUT',
    orderDeliveries: 'Order Deliveries',
    orderDeliveriesHeading: 'KEEP ON TRACK',
    invoiceProcessing: 'Invoice Processing',
    invoiceProcessingHeading: 'STAY IN CONTROL',
    approvalRules: 'Approval Rules',
    approvalRulesHeading: 'MADE FOR YOUR ORGANISATION',
    assetTracking: 'Asset Tracking',
    assetTrackingHeading: 'TRACEABILITY ENSURED',
    analytics: 'Analytics',
    analyticsHeading: 'SEE THE WHOLE PICTURE',
    dataIntegration: 'Data & Integration',
    dataIntegrationHeading: 'TIE EVERYTHING TOGETHER',
  },
}

const appUrlsByLang = {
  '': 'https://app.approvol.com',
  'zh-cn': 'https://app-zhcn.approvol.com',
}

const NavBar = ({ toggleNavbar, isActive, lang }) => {
  const closeNavbar = () => { if (isActive) toggleNavbar() }
  const langUriPrefix = lang ? '/' + lang : ''
  return (
    <div>
      <nav className='navbar is-fixed-top' aria-label='main navigation'>
        <div className='container'>
          <div className='navbar-brand'>
            <Link to={`${langUriPrefix}/`} className='navbar-item'>
              <img src='/img/logo.svg' alt='logo' id='navbar-logo-img' />
            </Link>
            <button
              className={`button navbar-burger is-white ${isActive ? 'is-active' : ''}`}
              data-target='navMenu'
              aria-label='menu'
              onClick={toggleNavbar}
            >
              <span aria-hidden='true' />
              <span aria-hidden='true' />
              <span aria-hidden='true' />
            </button>
          </div>
          <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id='navMenu'>

            <div className='navbar-end'>
              {/* <SearchBox searchIndex={data.siteSearchIndex.index} /> */}
              <div className='navbar-item has-dropdown is-hoverable'>
                <Link className='navbar-link' to={`${langUriPrefix}/solutions`} onClick={closeNavbar}>
                  {captions[lang].solutions}
                </Link>
                <div className='navbar-dropdown'>
                  <Link className='navbar-item' to={`${langUriPrefix}/solutions/budget-control`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].budgetControl}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].budgetControlHeading}</span>
                    </p>
                  </Link>
                  <Link className='navbar-item' to={`${langUriPrefix}/solutions/purchase-management`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].purchaseRequests}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].purchaseRequestsHeading}</span>
                    </p>
                  </Link>
                  <Link className='navbar-item' to={`${langUriPrefix}/solutions/order-delivery`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].orderDeliveries}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].orderDeliveriesHeading}</span>
                    </p>
                  </Link>
                  <Link className='navbar-item' to={`${langUriPrefix}/solutions/invoice-processing`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].invoiceProcessing}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].invoiceProcessingHeading}</span>
                    </p>
                  </Link>
                  {/* <Link className='navbar-item' to={`${langUriPrefix}/solutions/approval-rules`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].approvalRules}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].approvalRulesHeading}</span>
                    </p>
                  </Link> */}
                  <Link className='navbar-item' to={`${langUriPrefix}/solutions/asset-tracking`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].assetTracking}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].assetTrackingHeading}</span>
                    </p>
                  </Link>
                  <Link className='navbar-item' to={`${langUriPrefix}/solutions/analytics`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].analytics}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].analyticsHeading}</span>
                    </p>
                  </Link>
                  <Link className='navbar-item' to={`${langUriPrefix}/solutions/data-integration`} onClick={closeNavbar}>
                    <p>
                      <span className='is-size-5 has-text-grey-darker'>{captions[lang].dataIntegration}</span>
                      <br />
                      <span className='is-size-7 has-text-grey'>{captions[lang].dataIntegrationHeading}</span>
                    </p>
                  </Link>
                </div>
              </div>
              <Link className='navbar-item' to={`${langUriPrefix}/use-cases`} onClick={closeNavbar}>
                {captions[lang].useCases}
              </Link>
              <Link className='navbar-item' to={`${langUriPrefix}/sectors`} onClick={closeNavbar}>
                {captions[lang].sectors}
              </Link>
              <Link className='navbar-item' to={`${langUriPrefix}/pricing`} onClick={closeNavbar}>
                {captions[lang].pricing}
              </Link>
              <Link className='navbar-item' to={`${langUriPrefix}/about`} onClick={closeNavbar}>
                {captions[lang].about}
              </Link>
              {lang ? '' : (
                <Link className='navbar-item' to={`${langUriPrefix}/blog`} onClick={closeNavbar}>
                  {captions[lang].blog}
                </Link>
              )}
              <div className='navbar-item'>
                <div className='field is-grouped'>
                  <p className='control'>
                    <Link
                      className='button is-primary has-button-shadow'
                      to={`${langUriPrefix}/contact`}
                      onClick={closeNavbar}>
                      {captions[lang].contactUs}
                    </Link>
                  </p>
                </div>
              </div>
              <div className='navbar-item'>
                <div className='field is-grouped'>
                  <p className='control'>
                    <a
                      className='button is-primary is-outlined has-button-shadow'
                      href={appUrlsByLang[lang]}
                      onClick={closeNavbar} target='approvol-app'>
                      {captions[lang].signIn}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavBar
